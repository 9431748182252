import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import AuthUser from './AuthUser';
import { useNavigate } from "react-router-dom";
// import { usePlacesWidget } from 'react-google-autocomplete';
// import Autocomplete from "react-google-autocomplete";
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import FormInput from '../utils/FormInput';

const customer_url = 'customer/'
const initialValues = {
    packageType: 1,
    shippingCarrier: "FEDEX",
    trackingNumber: "",
    pickupState: '',
    pickupCounty: '',
    pickupCity: '',
    dropLocation: '',
    pickupAddress: "",
    pickupDate: "",
    pickupTime: ""
};

export default function SchedulePickup() {


    const navigate = useNavigate();
    const {http} = AuthUser();
    const [packages, setPackages] = useState([]);
    const [packageTypes, setPackageTypes] = useState({});
    const [dropLocations, setDropLocations] = useState({});
    const [pickupTimes, setPickupTimes] = useState({});
    const [carriers, setCarriers] = useState({});
    const [states, setStates] = useState([]);
    const [counties, setCounties] = useState([]);
    const [price,setPrice] = useState(0);
    const alert = useAlert();
    const [submitProcess, setSubmitProcess] = useState(false);

    const [values, setValues] = useState(initialValues);
    const [pickupAddress, setPickupAddress] = useState('');
    const [trackingPattern, setTrackingPattern] = useState('[0-9]*');
    const [trackingPatternMsg, setTrackingPatternMsg] = useState("Please enter valid tracking number only!");

    // reset form values after form submitting
    const resetForm = () => {
        setValues(initialValues);
    };

    useEffect(()=>{
        fetchUserDetail();
    }, []);

    const fetchUserDetail = () =>{
        try {
            http.post(customer_url+'packages-list').then((res)=>{
                setPackages(res.data.data.packages);
                setPackageTypes(res.data.data.packageTypes);
                setDropLocations(res.data.data.dropLocations);
                setPickupTimes(res.data.data.pickupTimes);
                setCarriers(res.data.data.carriers);
                setStates(res.data.data.states);
                setCounties(res.data.data.counties);
            });
        } catch (error) {
            alert.error(error);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        try {
            setSubmitProcess(true);
            http.post(customer_url + 'schedule-pickup-store', {...values, pickupAddress, additionalTracking}).then((res) => {
                if (res.data.status === 'success') {
                    alert.success(res.data.message);
                    resetForm();
                    navigate('/schedule-pickup-payment/'+ res.data.data.order.id)
                } else {
                    alert.info(res.data.message);
                }
                setSubmitProcess(false);
            })
        } catch (error) {
            alert.error(error);
        }
    };


    // let  countyOptions = Object.values(packages).filter( item => item.shop_id == values.dropLocation);
    const onChange = (e) => {

        if (e.target.name==='pickupState') {
            setValues({ ...values, [e.target.name]: e.target.value, pickupCounty: '', pickupCity: '' });
        }
        else if (e.target.name==='pickupCounty') {
            setValues({ ...values, [e.target.name]: e.target.value, pickupCity: '' });
        }
        else if(e.target.name === 'pickupCity'){
            let selectedPackage = Object.values(packages).find(pack => e.target.value == pack.id);
            setPrice( selectedPackage.price);
            setValues({ ...values, [e.target.name]: e.target.value, dropLocation: selectedPackage.shop_id });
        } 
        else {        
            setValues({ ...values, [e.target.name]: e.target.value });
        }

        if ( e.target.name==='shippingCarrier' ) {
            //reset tracking number field(s)
            setValues({ ...values, trackingNumber: '' });
            setAdditionalTracking([]);

            if ( e.target.value==='FEDEX' || e.target.value==='DHL' ) { // only number allowed
                setTrackingPattern('[0-9]*');
                setTrackingPatternMsg('Please enter valid tracking number only');
            }
            else if ( e.target.value==='UPS' ) { // begein with 1Z
                setTrackingPattern('^(1Z)+[A-Za-z0-9]*');
                setTrackingPatternMsg('Please enter valid tracking number start with 1Z');
            }
            else if ( e.target.value==='USPS' ) { // begein with 9
                setTrackingPattern('[9]+[0-9]*');
                setTrackingPatternMsg('Please enter valid tracking number start with 9');
            }
        }

        // console.log(e.target.name);
        // console.log(values);
    };



    // for child component direct setValues does not work properly
    const onPlaceChange = (place) => {
        setPickupAddress(place.formatted_address);
    }


    const [additionalTracking, setAdditionalTracking] = useState([]);
    const additionalAdd = () => {
        setAdditionalTracking([...additionalTracking, {additionalTrackingNo:""}])
    }
    const additionalRemove = (i) => {
        const removeVal = [...additionalTracking]
        removeVal.splice(i, 1)
        setAdditionalTracking(removeVal)
    }
    const additionalChange = (e, i) => {
        const {name, value} = e.target
        const onChangeVal = [...additionalTracking]
        onChangeVal[i][name] = value
        setAdditionalTracking(onChangeVal)
    }

    function renderElement(){
        if(packages){
            return <div>
                <div className="row justify-content-center">
                    <div className="col-sm-10">
                        <div className="card p-4">
                            <form onSubmit={handleSubmit} autoComplete="off">
                            <div className="mb-3">
                                <label>Select Package type:</label>
                                <select className="form-control" name="packageType" onChange={onChange} required>
                                    {Object.keys(packageTypes).map( (key, i) => {
                                        return <option key={key} value={key}>{packageTypes[key]}</option>
                                    })}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label>Select Shipping Carrier:</label>
                                <select className="form-control" name="shippingCarrier" onChange={onChange} required>
                                    {Object.keys(carriers).map( (key, i) => {
                                        return <option key={key} value={key}>{carriers[key]}</option>
                                    })}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label>Carrier Tracking number:</label>
                                <div className='row'>
                                    <div className='col-sm-9'>
                                    {/* <input type="text" name="trackingNumber" onChange={trackingValidation} value={values.trackingNumber} className="form-control" placeholder="Enter tracking number" required /> */}
                                    <FormInput
                                        key={134}
                                        name="trackingNumber"
                                        type="text"
                                        placeholder="Enter tracking number"
                                        errorMessage={trackingPatternMsg}
                                        pattern={trackingPattern}
                                        required={true}
                                        value={values['trackingNumber']}
                                        onChange={onChange}
                                    />
                                    </div>
                                    <div className='col-sm-3'>
                                    <button onClick={additionalAdd} type='button' className='btn btn-primary btn-md btn-block'> + Add More Tracking Number</button>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3">
                                
                                {
                                    additionalTracking.map( (val, i) => 
                                    <div className='row mb-2'>
                                        <div className='col-sm-9'>    
                                        <FormInput
                                            key={(i+1)*31}
                                            name="additionalTrackingNo"
                                            type="text"
                                            placeholder="Enter additional tracking number"
                                            errorMessage={trackingPatternMsg}
                                            pattern={trackingPattern}
                                            required={true}
                                            onChange={e=>additionalChange(e, i)} 
                                            value={val.additionalTrackingNo}
                                        />
                                        </div>
                                        <div className='col-sm-3'>
                                            <button type='button' className='btn btn-danger btn-md' onClick={()=>additionalRemove(i)}>Remove</button>
                                        </div>
                                    </div>
                                    )
                                }
                            </div>

                            <div className="mb-3">
                                <label>Package Pickup State:</label>
                                <select className="form-control" name="pickupState" onChange={onChange} required>
                                    <option value=''>Select Pickup State</option>
                                    {states.map( (item, i) => {
                                        return <option key={item.id} value={item.id}>{item.name}</option>
                                    })}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label>Package Pickup County:</label>
                                <select className="form-control" name="pickupCounty" onChange={onChange} required >
                                    <option value=''>Select Pickup County</option>
                                    {counties.filter(e=>e.state_id==values.pickupState).map( (item, i) => {
                                        return <option key={item.id} value={item.id}>{item.name} County</option>
                                    })}
                                </select>

                            </div>
                            <div className="mb-3">
                                <label>Package Pickup City:</label>
                                <select className="form-control" name="pickupCity" onChange={onChange} required >
                                    <option value=''>Select Pickup City</option>
                                    {packages.filter(e=>e.county_id==values.pickupCounty).map( (item, i) => {
                                        return <option key={item.id} value={item.id}>{item.name}</option>
                                    })}
                                </select>

                            </div>
                            <div className="mb-3">
                                <label>Your package will be processed by:</label>
                                <select className="form-control" name="dropLocation" onChange={onChange} required>
                                    {/* <option value=''>Select Package Dispatch Location</option> */}
                                    { values.dropLocation=='' ? (
                                        Object.keys(dropLocations).map( (key, i) => {
                                            return <option key={(i+1)*414} value={key}>{dropLocations[key]}</option>
                                        })
                                     ) : (
                                        Object.keys(dropLocations).filter(e=>e==values.dropLocation).map( (key, i) => {
                                            return <option key={(i+1)*214} value={key}>{dropLocations[key]}</option>
                                        })
                                     )}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label>Enter Full pickup Address:</label>
                                <ReactGoogleAutocomplete
                                    className='form-control'
                                    placeholder="Enter pickup address"
                                    apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                                    options={{
                                        types: [],
                                        componentRestrictions: { country: "us" },
                                    }}
                                    onPlaceSelected={onPlaceChange}
                                    />
                            </div>
                            <div className="mb-3">
                                <label>Select Pickup Date:</label>
                                <input type="date" name="pickupDate" className="form-control" placeholder="Enter pickup date"
                                    onChange={onChange} required />
                            </div>
                            <div className="mb-3">
                                <label>Select Pickup Time:</label>
                               <select className="form-control" name="pickupTime" onChange={onChange} required>
                                <option value=''>Select Pickup Time</option>
                                    {Object.keys(pickupTimes).map( (key, i) => {
                                        return <option key={key} value={key}>{pickupTimes[key]}</option>
                                    })}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label>Pick up and Drop off Charges:</label>
                                <input type="text" name="charge" className="form-control" value={price}
                                 readOnly required />
                            </div>
                            <div className="text-center form-group mt-3">
                                {
                                    submitProcess 
                                    ? 
                                    <button type="button" className="btn btn-primary mt-4">Please wait...</button> 
                                    : 
                                    <button type="submit" className="btn btn-primary mt-4">Pay and Schedule Package Pickup</button>
                                }
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        }else{
            return <p className="d-block text-center pt-2">Loading.....</p>
        }

    }

    return(
        <div>
            <h1 className='mb-4 mt-4'>Schedule a Package Pickup</h1>
            { renderElement() }
        </div>
    )
}