import { useState } from "react"
import { Link, useNavigate } from 'react-router-dom';
import AuthUser from './AuthUser';
import FormInput from "../utils/FormInput";
import { useAlert } from "react-alert";
import { useDocumentTitle } from "../utils/setDocumentTitle";
import LeftServices from "./common/LeftServices";
import RightServices from "./common/RightServices";
import Footer from "../navbar/footer";

export default function Register() {
  const [document_title, setDoucmentTitle] = useDocumentTitle('Register');
    const navigate = useNavigate();
    const {http,setToken} = AuthUser();
    const alert = useAlert();
    const [submitProcess, setSubmitProcess] = useState(false);

    const [values, setValues] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        password: "",
        password_confirmation: "",
        otp: "",
        step: 1
    });

  const inputs = [
    {
      id: 1,
      name: "firstName",
      type: "text",
      placeholder: "Enter your first name",
      errorMessage:
        "first name should be 2-20 characters only!",
      label: "First Name",
      pattern: "^[A-Za-z]{2,20}$",
      required: true,
    },
    {
      id: 2,
      name: "lastName",
      type: "text",
      placeholder: "Enter your last name",
      errorMessage:
        "last name should be 2-20 characters only!",
      label: "Last Name",
      pattern: "^[A-Za-z]{2,20}$",
      required: true,
    },
    {
      id: 3,
      name: "email",
      type: "email",
      placeholder: "Enter your email",
      errorMessage: "It should be a valid email address!",
      label: "Email",
      required: true,
    },
    {
      id: 4,
      name: "phone",
      type: "text",
      placeholder: "Enter your phone",
      errorMessage: "It should be a valid phone number!",
      label: "Phone",
      pattern: "^[0-9]{10,11}$",
      required: true
    },
    {
      id: 5,
      name: "password",
      type: "password",
      placeholder: "Password",
      errorMessage:
        "Password should be 8-20 characters and include at least 1 letter, 1 number and 1 special character!",
      label: "Password",
      pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
      required: true,
    },
    {
      id: 6,
      name: "password_confirmation",
      type: "password",
      placeholder: "Confirm Password",
      errorMessage: "Passwords doesn't match!",
      label: "Confirm Password",
      pattern: values.password,
      required: true,
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      setSubmitProcess(true);
      http.post('customer-join', values).then((res)=>{
        if ( res.data.status==='success' ) {
          alert.success(res.data.message);
          setValues({ ...values, step: 2 });
        } else {
          alert.info(res.data.message);
        }
        setSubmitProcess(false);
        console.log(res.data);
        // navigate('/login')
      })
    } catch (error) {
      alert.error(error);
    }
  };
  
  const registerWithOtp = (e) => {
    e.preventDefault();
    try {
      setSubmitProcess(true);
      http.post('customer-join-otp', values).then((res)=>{
        if ( res.data.status==='success' ) {
          alert.success(res.data.message);
          setToken(res.data.data.user, res.data.data.token);
        } else {
          alert.info(res.data.message);
        }
        setSubmitProcess(false);
        console.log(res.data);
        // navigate('/login')
      })
    } catch (error) {
      alert.error(error);
    }
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };



    return(
        <div className="row justify-content-center0 pt-5 login-form-container">
            <div className="col-sm-12 col-md-6 login-form-left">

            <div className="left-toppart">
            <LeftServices />  
            <RightServices />  
            </div>
            <div className="left-bottompart">      
            <hr />
            <Footer />
            </div>
            </div>
            <div className="col-sm-12 col-md-6 login-form-body">
                <div className="card p-4">
                    <img className="login-logo" src="/logo.png" title="Shika365 Scheduler"></img>
                    <h1 className="text-center mb-3">Register </h1>
                    {
                      values['step']==1 ? (
                      <form onSubmit={handleSubmit}>
                        {inputs.map((input) => (
                        <FormInput
                            key={input.id}
                            {...input}
                            value={values[input.name]}
                            onChange={onChange}
                        />
                        ))}
            
                        <div className="text-center form-group mt-3">
                          {
                              submitProcess 
                              ? 
                              <button type="button" className="btn btn-primary mt-4">Please wait...</button> 
                              : 
                              <button type="submit" className="btn btn-primary mt-4">Register</button>
                          }                      
                      </div>
                        <div className="form-group mt-3 text-center">
                            Do you have account? <Link to="/login">Login</Link>
                        </div>
                      </form>
                      ) : (
                      <form onSubmit={registerWithOtp} >
                        <FormInput
                          key={11}
                          name="otp"
                          type="text"
                          placeholder="Enter verification code"
                          errorMessage="Please enter the verification code!"
                          required="true"
                          value={values['otp']}
                          onChange={onChange}
                      />
                        <div className="form-group mt-3 text-center">
                          {
                              submitProcess 
                              ? 
                              <button type="button" className="btn btn-primary mt-4">Please wait...</button> 
                              : 
                              <button type="submit" className="btn btn-primary mt-4">Verify Code</button>
                          }
                        </div>
                      </form>
                      )
                    }

                </div>
            </div>
        </div>
    )
}