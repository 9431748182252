import { Link } from "react-router-dom";
import { useDocumentTitle } from "../utils/setDocumentTitle";
import { useState } from "react";
import Header from "../navbar/header";
import AuthUser from "./AuthUser";

export default function PrivacyPolicy() {
    useDocumentTitle('Privacy & Policy');

    return (
        <>
 
        <div className='backend-page privacy-page'>
            

            <div className="p-5">


    <h1 className="text-center m-4">Privacy Policy for Shika365 Scheduler</h1>
    <p>Effective Date: June 12, 2023</p>

    <h2>1. Information Collection</h2>
    <h3>1.1 Personal Information</h3>
    <p>
        We may collect personal identification information from Users in various ways, including but not limited to when Users register on the App, fill out a form, or interact with the App's features. The personal information we collect may include the following:
    </p>
    <ul>
        <li>Name: We collect your name to personalize your experience within the App.</li>
        <li>Email Address: We collect your email address to verify your identity and communicate important updates and notifications.</li>
        <li>Password: We collect a password to authenticate and secure your account.</li>
    </ul>
    <p>
        You have the right to refuse to provide us with your personal information; however, this may prevent you from accessing certain features and services offered by the App.
    </p>

    <h3>1.2 Non-Personal Information</h3>
    <p>
        We may collect non-personal identification information about Users whenever they interact with the App. This may include technical information about Users' devices, such as the device model, operating system version, unique device identifiers, and mobile network information.
    </p>

    <h2>2. Information Usage</h2>
    <p>
        We may collect and use Users' personal information for the following purposes:
    </p>
    <ul>
        <li>To authenticate and secure User accounts: Your personal information, including your email address and password, is used to verify your identity and ensure the security of your account.</li>
        <li>To improve customer service: The information you provide helps us respond to your customer service requests more effectively.</li>
        <li>To personalize user experience: We may use your information to personalize your experience within the App and provide customized content and features.</li>
        <li>To send periodic emails: We may use your email address to send you important information and updates regarding the App, as well as occasional promotional messages. You may unsubscribe from receiving these emails at any time by following the instructions provided in the email.</li>
    </ul>

    <h2>3. Information Protection</h2>
    <p>
        We adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information stored on our App.
    </p>

    <h2>4. Information Sharing</h2>
    <p>
        We do not sell, trade, or rent Users' personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding our Users with our business partners, trusted affiliates, and advertisers for the purposes outlined above.
    </p>

    <h2>5. Third-Party Websites and Services</h2>
    <p>
        The App may contain links to third-party websites or services that are not operated by us. We have no control over the content, privacy policies, or practices of any third-party websites or services. We encourage Users to read the privacy policies of any third-party websites they visit.
    </p>

    <h2>6. Changes to this Privacy Policy</h2>
    <p>
        We reserve the right to update or modify this Privacy Policy at any time. Any changes we make to this Privacy Policy will be posted on this page, and the updated date will be indicated at the top of the page. We encourage Users to review this Privacy Policy periodically for any changes.
    </p>

    <h2>7. Your Acceptance of these Terms</h2>
    <p>
        By using the Shika365 Scheduler App, you signify your acceptance of this Privacy Policy. If you do not agree to this Privacy Policy, please do not use the App. Your continued use of the App following the posting of changes to this Privacy Policy will be deemed as your acceptance of those changes.
    </p>

    <h2>8. Contact Us</h2>
    <p>
        If you have any questions about this Privacy Policy or the practices of the Shika365 Scheduler App, please contact us at <a target="_blank" href="https://shika365.com/support">Click to Contact</a>.
    </p>

    <p>
        This Privacy Policy was last updated on June 12, 2023.
    </p>

            </div>
        </div>
        </>
    )
}