import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AuthUser from '../components/AuthUser';

const Header = () => {
    const { token, user, isAuth, logout } = AuthUser();
    const [navOpened, setNavOpened] = useState(false);

    const toggleNav = () => {
        setNavOpened(!navOpened);
    }

    const logoutUser = () => {
        if (token != undefined) {
            logout();
        }
    }
    return <>
        <heaeer>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container">
                    {isAuth() ?
                        <Link className="navbar-brand" to="/dashboard">Shika365 Scheduler</Link>
                        : <Link className="navbar-brand" to="/">Shika365 Scheduler</Link>
                    }

                    <button className="navbar-toggler" type="button" onClick={toggleNav} aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={navOpened ? 'collapse navbar-collapse show' : 'collapse navbar-collapse'} id="navbarNav">
                        {isAuth() ? (
                            <ul className="navbar-nav ms-auto">

                                <li className="nav-item">
                                    <Link className="nav-link" onClick={toggleNav} to="/dashboard">Dashboard</Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                        Services
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        <li className='nav-item'>
                                            <Link className="nav-link" onClick={toggleNav} to="/schedule-pickup">Schedule a Package Pickup</Link>
                                        </li>
                                        <li className='nav-item'>
                                            <Link className="nav-link" onClick={toggleNav} to="/notary-service">Schedule a Notary Service</Link>
                                        </li>
                                        <li className='nav-item'>
                                            <Link className="nav-link" onClick={toggleNav} to="/fingerprint-appointment">Schedule a Fingerprint Appointment</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                        Transaction History
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        <li className='nav-item'>
                                            <Link className="nav-link" onClick={toggleNav} to="/pickup-history">Package Pickups</Link>
                                        </li>
                                        <li className='nav-item'>
                                            <Link className="nav-link" onClick={toggleNav} to="/fingerprint-history">Fingerprints Appointments</Link>
                                        </li>
                                        <li className='nav-item'>
                                            <Link className="nav-link" onClick={toggleNav} to="/notary-history">Notary Appointments</Link>
                                        </li>
                                        <li className='nav-item'>
                                            <Link className="nav-link" onClick={toggleNav} to="/payment-transaction">Payment Transactions</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" onClick={toggleNav} to="/profile">Profile</Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                        Welcome {user.first_name}
                                    </a>

                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        <li className="nav-item">
                                            <Link className="nav-link" onClick={toggleNav} to="/change-password">Change Password</Link>
                                        </li>
                                        <li className="nav-item">
                                            <span role="button" className="nav-link" onClick={logoutUser}>Logout</span>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        ) :
                            <ul className="navbar-nav ms-auto">

                                <li className="nav-item">
                                    <Link className="nav-link" onClick={toggleNav} to="/">Login</Link>
                                </li>

                            </ul>
                        }
                    </div>

                </div>
            </nav>
        </heaeer>
    </>
}
export default Header;