import { useEffect, useState } from 'react';
import AuthUser from './AuthUser';
import { useDocumentTitle } from '../utils/setDocumentTitle';
import { useAlert } from 'react-alert';
import {NotaryStatus} from "../utils/Helpers";

export default function NotaryHistory() {
    useDocumentTitle('Notary History');
    const alert = useAlert();
    const {http} = AuthUser();
    const [list, setList] = useState();

    useEffect(()=>{
        fetchInitialData();
    },[]);

    const fetchInitialData = () =>{
        try {
            http.get('customer/get-notary-booked-appointments').then((res)=>{
                if ( res.data.status==='success' ) {
                    setList(res.data.data.list);
                } else {
                    alert.info(res.data.message);
                }

            });
        }  catch (error) {
            alert.error(error);
        }        
    }

    function renderElement(){
        if(list){
            return <div>
                <div className='row'>
                    <div className='col-md-12 table-responsive'>
                        <table className='table table-hover table-dark mytbl'>
                            <thead>
                            <tr>
                                <th>Order ID</th>
                                <th>Document Notary Type</th>
                                <th>Appointment Date</th>
                                <th>Appointment Time</th>
                                <th>Timezone</th>
                                <th>Customer Name</th>
                                <th>Shop Location</th>
                                <th>Order Status</th>
                                <th>Date of Transaction</th>
                            </tr>
                            </thead>
                            <tbody>
                            {list.length>0 ? list.map((row, index) => {
                                return <tr className="table-secondary" key={index}>
                                    <td data-label="Order ID">{row.order_id}</td>
                                    <td data-label="Notary Type">{row.selected_notary?.title}</td>
                                    <td data-label="Appointment Date">{row.date}</td>
                                    <td data-label="Appointment Time">{row.timeslot}-{row.end_time}</td>
                                    <td data-label="Timezone">{row.timezone}</td>
                                    <td data-label="Customer Name">{row.first_name} {row.last_name}</td>
                                    <td data-label="Shop Location">{row.shop_location}</td>
                                    <td data-label="Order Status">{ NotaryStatus(row.order.order_status) }</td>
                                    <td data-label="Transaction Date">{row.created_at}</td>
                                    {/* <td><Link to={`/appointment-show/${row.id}`}>View</Link></td> */}
                                </tr>;
                            }) : <tr className="table-secondary"><td colSpan="10" className='text-center'>No data found!</td></tr> }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        }else{
            return <p>Loading.....</p>
        }

    }

    return(
        <div>
            <h1 className='mb-4 mt-4'>Notary History</h1>
            { renderElement() }
        </div>
    )
}