import { useEffect, useState } from 'react';
import AuthUser from './AuthUser';
import { useDocumentTitle } from '../utils/setDocumentTitle';
import { useNavigate } from 'react-router-dom';

export default function Dashboard() {
    const navigate = useNavigate();
    useDocumentTitle('Dashboard');
    const {http, user, updateUser} = AuthUser();
    const [userdetail,setUserdetail] = useState('');

    useEffect(()=>{
        fetchUserDetail();
    },[]);

    const fetchUserDetail = () =>{
        http.post('customer/info').then((res)=>{
            updateUser(res.data.data.user);
        });
    }

    const goTo = (url) => {
        navigate(url);
    }

    function renderElement(){
        if(user){
            return <div>
                <h5 className='mb-4 mt-4'>Services</h5>
                <div className='row'>
                    <div className='col-md-4'>
                        <div className='card'>
                            <div className='btn btn-default card-body text-center cursor-pointer' onClick={e=>goTo('/schedule-pickup')}>
                                <h6>Schedule a Package Pickup</h6>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='card'>
                            <div className='btn btn-default card-body text-center cursor-pointer' onClick={e=>goTo('/notary-service')}>
                                <h6>Schedule a Notary Service</h6>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='card'>
                            <div className='btn btn-default card-body text-center cursor-pointer' onClick={e=>goTo('/fingerprint-appointment')}>
                                <h6>Schedule a Fingerprint Appointment</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <h5 className='mb-4 mt-4'>Transaction History</h5>
                <div className='row'>
                    <div className='col-md-3'>
                        <div className='card'>
                            <div className='btn btn-default card-body text-center cursor-pointer' onClick={e=>goTo('/pickup-history')}>
                                <h6>Package Pickups</h6>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='card'>
                            <div className='btn btn-default card-body text-center cursor-pointer' onClick={e=>goTo('/fingerprint-history')}>
                                <h6>Fingerprints Appointments</h6>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='card'>
                            <div className='btn btn-default card-body text-center cursor-pointer' onClick={e=>goTo('/notary-history')}>
                                <h6>Notary Appointments</h6>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='card'>
                            <div className='btn btn-default card-body text-center cursor-pointer' onClick={e=>goTo('/payment-transaction')}>
                                <h6>Payment Transactions</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }else{
            return <p>Loading.....</p>
        }

    }

    return(
        <div>
            <h2 className='mb-4 mt-4'>Dashboard</h2>
            { renderElement() }
        </div>
    )
}