import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import FormInput from '../utils/FormInput';
import AuthUser from './AuthUser';
import { useDocumentTitle } from '../utils/setDocumentTitle';
import { Modal, Button } from 'react-bootstrap';

export default function Profile() {
    useDocumentTitle('Profile');
    const {http, user, updateUser, logout} = AuthUser();
    const [userdetail,setUserdetail] = useState(user);

    const alert = useAlert();
    const [submitProcess, setSubmitProcess] = useState(false);

    const [values, setValues] = useState({
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        phone: user.phone
    });

  const inputs = [
    {
      id: 1,
      name: "firstName",
      type: "text",
      placeholder: "Enter your first name",
      errorMessage:
        "first name should be 2-20 characters only!",
      label: "First Name",
      pattern: "^[A-Za-z]{2,20}$",
      required: true,
    },
    {
      id: 2,
      name: "lastName",
      type: "text",
      placeholder: "Enter your last name",
      errorMessage:
        "last name should be 2-20 characters only!",
      label: "Last Name",
      pattern: "^[A-Za-z]{2,20}$",
      required: true,
    },
    {
      id: 3,
      name: "email",
      type: "email",
      placeholder: "Enter your email",
      errorMessage: "It should be a valid email address!",
      label: "Email",
      required: true,
      readOnly: true,
      additionalclass: "disabled"
    },
    {
      id: 4,
      name: "phone",
      type: "text",
      placeholder: "Enter your phone",
      errorMessage: "It should be a valid phone number!",
      label: "Phone",
      pattern: "^[0-9]{10,11}$",
      required: true
    }
  ];

    useEffect(()=>{
        // document.title = "Profile";
        // fetchUserDetail();
    },[]);

    const fetchUserDetail = () =>{
        http.post('customer/info').then((res)=>{
            setUserdetail(res.data.data.user);
        });
    }

    const updateProfile = (e) => {
        e.preventDefault();
        try {
        setSubmitProcess(true);
        http.post('customer/profile/update', values).then((res)=>{
            if ( res.data.status==='success' ) {
                alert.success(res.data.message);
                updateUser(res.data.data.user);
            } else {
                alert.info(res.data.message);
            }
            setSubmitProcess(false);
            console.log(res.data);
            // navigate('/login')
        })
        } catch (error) {
        alert.error(error);
        }
  };

  const deleteAccount = (e) => {
    initModal(); // hide modal
    try {
        setSubmitProcess(true);
        http.post('delete-account', values).then((res)=>{
            if ( res.data.status==='success' ) {
                alert.success(res.data.message);
                logout();
            } else {
                alert.info(res.data.message);
            }
            setSubmitProcess(false);
        })
    } catch (error) {
        alert.error(error);
    }
};


  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const [isShow, invokeModal] = useState(false);
  const initModal = () => {
    return invokeModal(!isShow);
  }

    function renderElement(){
        if(userdetail){
            return <div>
                <Modal show={isShow}>
                    <Modal.Header closeButton onClick={initModal}>
                    <Modal.Title>Confirmation of Account Deletion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h5>Are you sure you want to delete your account?</h5>
                        <h5>If you delete, your all data like orders, appointments, transactions will be deleted and can not be restored</h5>
                        <h5>Still do you want to delete your account?</h5>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="danger" onClick={initModal}>
                        No
                    </Button>
                    <Button variant="dark" onClick={deleteAccount}>
                        Yes, Delete my account
                    </Button>
                    </Modal.Footer>
                </Modal>
                <div className='row'>
                    {/* <div className='col-md-4'>
                        <div className='card'>
                            <div className='card-body text-center'>
                                <img className='profile-pic' src='/img/profile.png' />
                                <h6>{user.first_name} {user.last_name}</h6>
                                <h6>Status: {user.status==1?'Active':user.status==2?'Pending':'Blocked'}</h6>                                
                            </div>
                        </div>
                    </div> */}

                    <div className='col-md-2'></div>
                    <div className='col-md-8'>
                        <div className='card'>
                            <div className='card-body '>
                                <form onSubmit={updateProfile}>
                                    {inputs.map((input) => (
                                    <FormInput
                                        key={input.id}
                                        {...input}
                                        value={values[input.name]}
                                        onChange={onChange}
                                    />
                                    ))}
                                    
                                    <div className='form-group'>
                                        <label>Status: </label>
                                        { user.status==1?' Active':user.status==2?' Pending':' Blocked' }
                                    </div>
                                    
                                    <div className="text-center form-group mt-3">
                                    {
                                        submitProcess 
                                        ? 
                                        <button type="button" className="btn btn-primary mt-4">Please wait...</button> 
                                        : 
                                        <button type="submit" className="btn btn-primary mt-4">Update Profile</button>
                                    }                      
                                    </div>
                                    <div className='form-group mt-5 mb-5'>
                                        <a href='#' onClick={initModal} className='text-danger'>Delete my account and remove my data from SHIKA365 system</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }else{
            return <p>Loading.....</p>
        }

    }

    return(
        <div>
            <h1 className='mb-4 mt-4'>Profile</h1>
            { renderElement() }
        </div>
    )
}