
export default function RightServices() {

    return(
        <div className="service-container">
            <h1>Other Services offered  by SHIKA365 LLC at our business locations</h1>
            <ul>
                <li>Physical address/Mailbox Rental Services</li>
                <li>Package Hold Services</li>
                <li>Package forwarding (Domestic and International) </li>
                <li>Photocopy, Fax, Scan</li>
                <li>Document Shredding</li>
            </ul>    
        </div>
    )
}