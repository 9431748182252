import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import AuthUser from './components/AuthUser';
import Guest from './navbar/guest';
import Auth from './navbar/auth';
import AutoLogout from "./components/AutoLogout";
import { useLocation } from "react-router-dom";

function App() {
  const {isAuth} = AuthUser();
  const { pathname } = useLocation();

  const hideHeaders = ['/', '/login', '/register', '/forgot-password'];
  const hideFooters = ['/', '/login', '/register', '/forgot-password'];

  if ( !isAuth() ) {
    return <Guest showHeader={!hideHeaders.includes(pathname)} showFooter={!hideFooters.includes(pathname)} />
  }
  return (
    <AutoLogout>
      <Auth />
    </AutoLogout>
  );
}

export default App;
