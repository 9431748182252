import { Routes, Route, Link } from 'react-router-dom';
import Home from '../components/home';
import Dashboard from '../components/dashboard';
import AuthUser from '../components/AuthUser';
import SchedulePickup from '../components/SchedulePickup';
import NotFound from '../components/NotFound';
import Profile from '../components/Profile';
import ChangePassword from '../components/ChangePassword';
import PaypalPayment from "../components/PaypalPayment";
import NotaryService from '../components/NotaryServices';
import FingerprintService from '../components/FingerprintServices';
import PickupHistory from '../components/PickupHistory';
import NotaryHistory from "../components/NotaryHistory";
import FingerprintHistory from "../components/FingerprintHistory";
import PaymentHistory from "../components/PaymentHistory";
import AppointmentShow from "../components/AppointmentShow";
import { useState } from 'react';
import ShikaDirectLogin from '../components/ShikaDirectLogin';
import Footer from './footer';
import Header from './header';
import PrivacyPolicy from '../components/PrivacyPolicy';

function Auth() {
    
    return (
        <div className='backend-page'>
            <Header />
            
            <div className="container" style={{minHeight:'90vh'}}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/schedule-pickup" element={<SchedulePickup />} />
                    <Route path="/schedule-pickup-payment/:id" element={<PaypalPayment />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/change-password" element={<ChangePassword />} />
                    <Route path="/pickup-history" element={<PickupHistory />} />
                    <Route path="/notary-service" element={<NotaryService />} />
                    <Route path="/fingerprint-appointment" element={<FingerprintService />} />
                    <Route path="/notary-history" element={<NotaryHistory />} />
                    <Route path="/fingerprint-history" element={<FingerprintHistory />} />
                    <Route path="/appointment-show/:id" element={<AppointmentShow />} />
                    <Route path="/payment-transaction" element={<PaymentHistory />} />
                    <Route path="/shika365/:authToken/:redirectPath" element={<ShikaDirectLogin />} />
                    <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </div>
            <Footer />
        </div>
    );
}

export default Auth;
