import { useEffect, useState } from 'react';
import { useDocumentTitle } from '../utils/setDocumentTitle';
import AuthUser from './AuthUser';
import { useAlert } from 'react-alert';

import countryList from '../countries.json';

// import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';

import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { Button, Modal } from "react-bootstrap";

// import moment from 'moment';
import moment from 'moment-timezone';
import {useNavigate} from "react-router-dom";
// import moment from "react-moment";

const todayDate = new Date();
const oneYearDate = moment(todayDate).add(12, 'm');
// var june = moment("2014-06-01T12:00:00Z");
// june.tz('America/Los_Angeles').format('ha z'); // 5am PDT

const initialServices = {
    'notary': false,
    'fingurePrint': false
};

const pageConfig = {
    'pageTitle': 'Schedule a Notary Service', // Schedule a Fingerprint Appointment
    'notary': true,
    'fingurePrint': false
}
 
const initialTimezons = [
    {id: 1, name: 'America/Anchorage', title: 'Alaska Time Zone'},
    {id: 2, name: 'America/Chicago', title: 'Central Time Zone'},
    {id: 3, name: 'America/Denver', title: 'Mountain Time Zone'},
    {id: 4, name: 'America/Indiana/Indianapolis', title: 'Eastern Time Zone - Indiana'},
    {id: 5, name: 'America/Los_Angeles', title: 'Pacific Time Zone'},
    {id: 6, name: 'America/New_York', title: 'Eastern Time Zone'},
    {id: 7, name: 'America/Phoenix', title: 'Mountain Time Zone - Arizona'},
    {id: 8, name: 'Pacific/Honolulu', title: 'Hawaii - Aleutian Time Zone'},
    {id: 9, name: 'Pacific/Pago_Pago', title: 'Samoa Time Zone'},
];

//FingerprintService NotaryService
export default function NotaryService() {
    useDocumentTitle(pageConfig.pageTitle);
    const {http, user} = AuthUser();
    const alert = useAlert();
    const navigate = useNavigate();
    const [moreActive,setMoreActive] = useState(false);
    const [moreActiveFingurePrint,setMoreActiveFingurePrint] = useState(false);
    const [services, setServices] = useState(initialServices);
    const [step, setStep] = useState(1);

    const [notaryPackages, setNotaryPackages] = useState([]);
    const [fingurePackages, setFingurePackages] = useState([]);
    const [addresses, setAddresses] = useState([]);

    const [selectedNotary, setSelectedNotary] = useState();
    const [selectedFingure, setSelectedFingure] = useState();

    const [timezones, setTimezones] = useState(initialTimezons)
    const [timezone, setTimezone] = useState("America/New_York");
    const [isShow, invokeModal] = useState(false)

    const [submitProcess, setSubmitProcess] = useState(false);

    const initModal = () => {
        return invokeModal(!isShow)
    }

    const [calanderDate, setCalanderDate] = useState(todayDate);
    const [calanderDateString, setCalanderDateString] = useState(new Date());
    const [timeslot, setTimeslot] = useState('');

    const [morningTimes, setMorningTimes] = useState([]);

    const getSelectedNotaryInfo = () => {
        let info = notaryPackages.find( item => item.id == selectedNotary );
        return <span>{info?.title} <br/>{info?.minutes} minutes</span>
    }
    const getSelectedFingurePrintInfo = () => {
        let info = fingurePackages.find( item => item.id == selectedFingure );
        return <span>{info?.title} <br/>{info?.minutes} minutes</span>
    }


    const getTotal = () => {
        let notaryInfo = (services.notary) ?  notaryPackages.find( item => item.id == selectedNotary )?.price : 0;
        let fingerInfo = (services.fingurePrint) ? fingurePackages.find( item => item.id == selectedFingure )?.price :0;
        return parseFloat(notaryInfo) + parseFloat(fingerInfo);
    }

    const [formData, setFormData] = useState({
        country: 'US',
        countryName: countryList.US.name,
        phoneCode: countryList.US.phone[0],
        phone: user.phone,
        email: user.email,
        firstName: user.first_name,
        lastName: user.last_name,
        note: '',
        ofsLocation: 1,
        paymentMethod: 2, // 2 paypal
    });

    const changeCountry = (e) => {
      let countryObj = countryList[e.target.value];
      setFormData({
        ...formData,
        country: e.target.value,
        countryName: countryObj.name,
        phoneCode: countryObj.phone[0],
      });
    };

    const calanderDateChange = (dateString) => {
        console.log(dateString);
        setCalanderDateString(dateString);  
        let date = moment(dateString).format('YYYY-MM-DD');        
        setCalanderDate(date); 
        http.post('customer/get-available-time', {
            date,
            ...services,
            selectedNotary,
            selectedFingure
        }).then((res) => {
             setMorningTimes(res.data.data.available)
        });
    };

    const checkoutNotaryInfo = () => {
      let notaryInfo = notaryPackages.find((item) => item.id == selectedNotary);
      return (
        <div>
          {notaryInfo.title}{" "}
          <span className="price-right">${notaryInfo.price}</span>
        </div>
      );
    };
    const checkoutFingerInfo = () => {
      let notaryInfo = fingurePackages.find(
        (item) => item.id == selectedFingure
      );
      return (
        <div>
          {notaryInfo.title}{" "}
          <span className="price-right">${notaryInfo.price}</span>
        </div>
      );
    };
    const getSelectedTimeSlot = () => {
        if (timeslot==='')
            return '';

        let selectedServicesLength = Object.values(services).filter(v=>v==true).length; // count of selected services

        let timeslotIndex = morningTimes.findIndex( v => v.time==timeslot);


        // let timeslotToIndex = timeslotIndex+selectedServicesLength;
        // if ( timeslotToIndex>morningTimes.length )
        //     timeslotToIndex = morningTimes.length;


        return <div>{moment.unix(morningTimes[timeslotIndex].timestamp).tz(timezone).format("hh:mm a")} - {moment.unix(morningTimes[timeslotIndex].timestamp).add((selectedServicesLength*30),'minutes').tz(timezone).format("hh:mm a")}</div>
        // return <div>{morningTimes[timeslotIndex].time_alias} - {morningTimes[timeslotToIndex].time_alias}</div>
        // return <div>{moment(morningTimes[timeslotIndex].time).tz(timezone).format("hh:mm: a")} - moment(morningTimes[timeslotToIndex].time).tz(timezone).format("hh:mm: a")</div>
    }

    useEffect(() => {
      init();
    }, []);

    const init = () =>{
          http.get('customer/get-appointments').then((res) => {
            let notaryPackagesResp = res.data.data.items.filter(item => item.appointment_type == 1);
            let fingerPackagesResp = res.data.data.items.filter(item => item.appointment_type == 2);
             setNotaryPackages(notaryPackagesResp);
             setFingurePackages(fingerPackagesResp);

             if ( notaryPackagesResp.length>0 ) {
                setSelectedNotary(notaryPackagesResp[0].id);
             }
             if ( fingerPackagesResp.length>0 ) {
                setSelectedFingure(fingerPackagesResp[0].id);
             }

             setAddresses(res.data.data.addresses);
         });
    }

    const toggleToActive = () => {
        setMoreActive(!moreActive);
    } 
    const toggleToActiveFingurePrint = () => {
        setMoreActiveFingurePrint(!moreActive);
    } 

    const continueHandler = () => {
        if ( step==1 && morningTimes.length==0 ) {
            calanderDateChange(calanderDateString);
        }
        setStep( prev=>prev+1 );
    }
    const backHandler = () => {
        setStep( prev=>prev-1 );
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        try {
            setSubmitProcess(true);
            http.post('customer/store-appointments', {
                ...formData,
                ...services,
                timezone,
                selectedNotary,
                selectedFingure,
                calanderDate,
                timeslot
            }).then((res) => {
                if (res.data.status == 'success') {
                    alert.success(res.data.message);
                    // resetForm();
                    if (formData.paymentMethod==2){ // paypal
                        navigate('/schedule-pickup-payment/'+ res.data.data.order.id);
                    } else {
                        if ( pageConfig.notary ) {
                            navigate('/notary-history');    
                        } else {
                            navigate('/fingerprint-history');
                        }                        
                    }
                } else {
                    alert.info(res.data.message);
                }
                setSubmitProcess(false);
            })
        } catch (error) {
            alert.error(error);
        }
    };

    function leftSideInfo() {
        return <>
            <ul className='service-left-menus'>
                <li className={step===1||step===2||step===3?'active':null}>Select Service</li>
                <div>
                    {services.notary ? (
                        <div className='selected-service'>
                            <h6>Document Notary Appointment</h6>
                            <p>{getSelectedNotaryInfo()}</p>
                        </div>
                    ):null}
                    {services.fingurePrint ? (
                        <div className='selected-service'>
                            <h6>Fingerprint Services</h6>
                            <p>{getSelectedFingurePrintInfo()}</p>
                        </div>
                    ):null}
                </div>
                <li className={step===2||step===3?'active':null}>Select date and time</li>
                    <div>
                        { (step > 1) ? (
                        <div className='selected-service'>
                            {moment(calanderDate).format("dddd, MMMM Do YYYY")}<br />
                            {getSelectedTimeSlot()}                                                      
                        </div>
                        ) : null } 
                    </div>
                <li className={step===3?'active':null}>Enter your details</li>
            </ul>
        </>
    }
    function stepOneElement(){
        return <>
            <h5>Select appointment location</h5>
            <select className='form-control mb-5' value={formData.ofsLocation} onChange={e=>setFormData({...formData, ofsLocation: e.target.value})}>
                {
                    Object.entries(addresses).map( (itemArr, i) => {
                        return <option key={i*442} value={itemArr[0]}>{itemArr[1]}</option>
                    })
                }
            </select>
            <h5>Select a service</h5>
            { pageConfig.notary ? (
                <div className='service-box'>
                <div className='row'>
                    <div className='col-md-8' >
                        <h5 onClick={e=>setServices({...services, notary: !services.notary})}>Document Notary Appointment</h5>
                        <h6 className={moreActive ? 'more-info-head active' : 'more-info-head'} onClick={toggleToActive}>More info <div className='more-info'>Notarizing documents</div></h6>
                        { services.notary ? (
                            <div className="box-inner-content">
                                <select className='form-control' value={selectedNotary} onChange={e=>setSelectedNotary(e.target.value)}>
                                    {
                                        notaryPackages.map( (item, i) => {
                                            return <option key={i*21} value={item.id}>{item.price} - {item.minutes} mins - {item.title}</option>
                                        })
                                    }
                                </select>
                            </div>
                        ) : null }
                    </div>
                    <div className='col-md-4'>
                        <input type='checkbox' checked={services.notary} onChange={e=>setServices({...services, notary: !services.notary})} className='service-checkbox' />
                    </div>
                </div>
                
            </div>
            ) : null }            

            { pageConfig.fingurePrint ? (
            <div className='service-box '>
                <div className='row'>
                    <div className='col-md-8' >
                        <h5 onClick={e=>setServices({...services, fingurePrint: !services.fingurePrint})}>Fingerprint Services</h5>
                        <h6 className={moreActiveFingurePrint ? 'more-info-head active' : 'more-info-head'} onClick={toggleToActiveFingurePrint}>More info <div className='more-info'>Book an appointment for your fingerprint needs.</div></h6>
                        { services.fingurePrint ? (
                            <div className="box-inner-content">
                                <select className='form-control' value={selectedFingure}  onChange={e=>setSelectedFingure(e.target.value)}>
                                    {
                                        fingurePackages.map( (item, i) => {
                                            return <option key={i*231} value={item.id}>{item.price} - {item.minutes} mins - {item.title}</option>
                                        })
                                    }
                                </select>
                            </div>
                        ) : null }
                    </div>
                    <div className='col-md-4'>
                        <input type='checkbox' checked={services.fingurePrint} onChange={e=>setServices({...services, fingurePrint: !services.fingurePrint})} className='service-checkbox' />
                    </div>
                </div>
                
            </div>
            ) : null }  
        </>
    }


    function stepTwoElement(){
        return <>
            <div className='calander-container mb-5 customDatePickerWidth'>
                {/* <Calendar value={moment(calanderDate).format('YYYY-MM-DD')} minDate={todayDate}  onChange={calanderDateChange} /> */}
                <ReactDatePicker
                selected={calanderDateString}
                onChange={calanderDateChange}
                minDate={new Date()}
                maxDate={oneYearDate}
                inline
                />
            </div>
            <div className='timezone-container mb-5'>
                <h5>Available on {moment(calanderDate).format("dddd, MMMM Do YYYY")}</h5>
                <div>You can schedule an appointment between 1 hour and 365 days ahead of time. Time zone: <span onClick={initModal}> { timezones.filter(v=>{ return v.name===timezone})[0].title }</span></div>
            </div>
            <div className='timeslot-container mb-5'>
                <h5>Morning</h5>
                 {morningTimes.length>0 ? morningTimes.map( (obj,i)=> {
                     if (moment.unix(obj.timestamp).tz(timezone).format("H") <= 11 ){
                        return <button key={i} type="button" onClick={e => setTimeslot(e.target.value)} value={obj.time}
                                       className={obj.time == timeslot ? 'btn btn-success me-3 mb-3 activated' : 'btn btn-primary me-3 mb-3'}>
                            {moment.unix(obj.timestamp).tz(timezone).format("hh:mm a")}
                        </button>
                     }
                }) : (
                    <button type="button" className='btn btn-default disabled me-3 mb-3'>All Booked</button>
                )}

                <h5>Afternoon</h5>
                {morningTimes.length>0 ? morningTimes.map( (obj,i)=> {
                    if (moment.unix(obj.timestamp).tz(timezone).format("H") >= 12 && moment.unix(obj.timestamp).tz(timezone).format("H") <= 17)
                    {
                        return <button key={i} type="button" onClick={e => setTimeslot(e.target.value)} value={obj.time}
                                       className={obj.time == timeslot ? 'btn btn-success me-3 mb-3 activated' : 'btn btn-primary me-3 mb-3'}>
                            {moment.unix(obj.timestamp).tz(timezone).format("hh:mm a")}
                        </button>
                    }
                }) : (
                    <button type="button" className='btn btn-default disabled me-3 mb-3'>All Booked</button>
                )}

                <h5>Evening</h5>
                {morningTimes.length>0 ? morningTimes.map( (obj,i)=> {
                    if (moment.unix(obj.timestamp).tz(timezone).format("H") >= 18 ){
                        return <button key={i} type="button" onClick={e => setTimeslot(e.target.value)} value={obj.time}
                                       className={obj.time == timeslot ? 'btn btn-success me-3 mb-3 activated' : 'btn btn-primary me-3 mb-3'}>
                            {moment.unix(obj.timestamp).tz(timezone).format("hh:mm a")}
                        </button>
                    }
                }) : (
                    <button type="button" className='btn btn-default disabled me-3 mb-3'>All Booked</button>
                )}
            </div>
        </>
    }

    function stepThreeElement() {
        return <>
            <form onSubmit={handleSubmit} autoComplete="off">
            <div className='calander-container'>
                <h5>You’re nearly done. Enter your details below.</h5>
                    <div className='row'>
                        <div className='col-md-6 mb-3'>
                            <select className='form-control' value={formData.country} onChange={changeCountry} required>
                                {Object.entries(countryList).map( (item, i) => {
                                    return <option key={i} phonecode={item[1].phone[0]} value={item[0]}>{item[1].name}</option>
                                })}
                            </select>
                        </div>
                        <div className='col-md-6 mb-3'>
                            <div className="input-group mb-2">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">{formData.phoneCode}</div>
                                </div>
                                <input type='text' value={formData.phone} readOnly className='form-control disabled' placeholder='Enter you phone' required/>
                            </div>
                        </div>
                        <div className='col-md-12 mb-3'>
                            <input type='email' value={formData.email} readOnly className='form-control disabled' placeholder='Enter your email' required />
                        </div>
                        <div className='col-md-6 mb-3'>
                            <input type='text' value={formData.firstName} readOnly className='form-control disabled' placeholder='Enter your first name' required />
                        </div>
                        <div className='col-md-6 mb-3'>
                            <input type='text' value={formData.lastName} readOnly className='form-control disabled' placeholder='Enter your last name' required />
                        </div>
                        <div className='col-md-12 mb-3'>
                            <textarea value={formData.note} onChange={e=>setFormData({...formData, note: e.target.value})} className='form-control' placeholder='Appoinment notes'></textarea>
                        </div>
                    </div>

            </div>
            <div>
                <h4>Prepay your appointment</h4>
                <h6>A prepayment of ${getTotal()} is required to book your appointment.</h6>
                <hr />
                <div>
                    {services.notary ? (
                        <div className='selected-service-checkout'>
                            <h6>Document Notary Appointment</h6>
                            <div>{checkoutNotaryInfo()}</div>
                        </div>
                    ):null}
                    {services.fingurePrint ? (
                        <div className='selected-service-checkout'>
                            <h6>Fingerprint Services</h6>
                            <div>{checkoutFingerInfo()}</div>
                        </div>
                    ):null}
                </div>
                <hr />
                <div className='mb-5'>
                    <h6>Sub Total <span className='price-right'>${getTotal()}</span></h6>
                    <h6>Tax <span className='price-right'>$0</span></h6>
                    <br />
                    <h5>Total <span className='price-right'>${getTotal()}</span></h5>
                </div>
                <hr />
                <div className='mb-5'>
                    <h6>Payment Method</h6>
                    <p><input type='radio' value="2" checked={formData.paymentMethod==2} onChange={ e=> setFormData({...formData, paymentMethod: e.target.value})} /> PayPal</p>
                    <p><input type='radio' value="1" checked={formData.paymentMethod==1} onChange={ e=> setFormData({...formData, paymentMethod: e.target.value})} /> Pay at Shop</p>
                </div>
                <hr />
                <div>
                    <h6>Policies</h6>
                    <p><input type="checkbox" required /> I have read and agree to the cancellation policy of SHIKA365 LLC. Please contact SHIKA365 LLC to learn more about their privacy practices.</p>
                </div>

            </div>
            <div className='timeslot-container text-center mb-5'>
            {
                submitProcess 
                ? 
                <button type="button" className="btn-block btn btn-primary">Please wait...</button> 
                : 
                <button type="submit" className='btn-block btn btn-primary'>Book Appoinment</button>
            }
            </div>
            </form>
        </>
    }

    function renderElement(){

            return <div>
                <div className='row'>
                    <div className='col-md-4'>
                        {leftSideInfo()}
                    </div>
                    <div className='col-md-8 services-container'>                       

                        { step===1 ? (
                            stepOneElement()
                        ) : step===2 ? (
                            stepTwoElement()
                        ) : (
                            stepThreeElement()
                        )}                        
                        
                    </div>
                    <hr />
                    <div>
                        {step>1 ? (
                            <button type='button' onClick={backHandler} className='btn btn-primary float-start'>Back</button>
                        ) : null}

                        { ((services.notary !=false || services.fingurePrint !=false ) && step == 1 ) &&  (
                            <button type='button' onClick={continueHandler} className='btn btn-primary float-end'>Continue</button>
                        )}
                        { (timeslot && step == 2 ) &&  (
                            <button type='button' onClick={continueHandler} className='btn btn-primary float-end'>Continue</button>
                        )}

                    </div>
                </div>
            </div>
        
    }

    return(
        <div>
            <h3 className='mb-4 mt-4'>{ pageConfig.pageTitle }</h3>
            { renderElement() }
            <Modal show={isShow}>
                <Modal.Header closeButton onClick={initModal}>
                    <Modal.Title>Select a time zone to display available times</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <select className='form-control' value={timezone} onChange={e=>setTimezone(e.target.value)}>
                        {timezones.map( (item, i) => {
                            return <option key={i} value={item.name}>{item.title} · Currently {moment(todayDate).tz(item.name).format("ha z")}</option>
                        })}

                    </select>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" onClick={initModal}>
                        Done
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}