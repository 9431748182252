import React from 'react';
import { isWebview } from '../utils/Helpers';
import { Link } from 'react-router-dom';
const Footer = () => {
    return <>
        <footer className='footer-bg' style={{marginTop: 20}}>
            {
                isWebview()==false ? (
                    <div className="download-apps-text">
                        <a href='https://play.google.com/store/apps/details?id=com.shikapps.scheduler'><img className="qrcode" src="/android-download.jpeg" alt="Download Android" /></a>
                        <a href='https://apps.apple.com/us/app/shika365-scheduler/id6450007455'><img className="qrcode" src="/ios-download.jpeg" alt="Download IOS" /></a>
                        <img className='qrcode' style={{height:100}} src='/android_ios_app_download.png' alt='' />
                    </div>
                ) : null
            }
            
            <div>
            {
                isWebview() ? null :
                <div className='text-center'>
                    <ul className='list-inline'>
                        <li className='list-inline-item'><a target='_blank' href='https://shika365.com/support'>Contact Us</a></li>
                        <li className='list-inline-item'><Link to='/privacy-policy'>Privacy Policy</Link></li>
                    </ul>
                </div>
            }
            </div>

            <div className='text-center p-3'>Powered by SHIKA365</div>
        </footer>
    </>
}
export default Footer;