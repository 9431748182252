import { useEffect, useState } from 'react';
import AuthUser from './AuthUser';
import { useDocumentTitle } from '../utils/setDocumentTitle';
import { useAlert } from 'react-alert';
import {useNavigate, useParams} from "react-router-dom";
import { Loader } from '../utils/Helpers';

export default function ShikaDirectLogin() {
    useDocumentTitle('Shika365');
    const alert = useAlert();
    const {http, setToken} = AuthUser();
    const [loading, setLoading] = useState(false);
    const params = useParams();
    const navigate = useNavigate();

    useEffect(()=>{
        handleShikaLogin();
    },[]);

    const handleShikaLogin = () => {
        // console.log(authToken);
        try {
            setLoading(true);
            http.post('shika365-login', {auth_token: params.authToken}).then((res)=>{
                if ( res.data.status==='success' ) {
                    setToken(res.data.data.user, res.data.data.token, params.redirectPath);
                } else {
                    alert.show(res.data.message);
                    navigate('/login');
                }
                setLoading(false);
            })
        } catch (error) {
            alert.error(error);
        }
    }

    function renderElement(){
        if(!loading){
            return <div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className="card">
                            <div className="card-body">
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }else{
            return Loader('Loading...')
        }

    }

    return(
        <div>
            { renderElement() }
        </div>
    )
}