import { useEffect, useState } from 'react';
import AuthUser from './AuthUser';
import { useDocumentTitle } from '../utils/setDocumentTitle';
import { useAlert } from 'react-alert';
import {useParams} from "react-router-dom";

export default function AppointmentShow() {
    useDocumentTitle('Appointment');
    const alert = useAlert();
    const {http} = AuthUser();
    const [appointment, setAppointment] = useState([]);
    const [googleEventedit, setGoogleEventedit] = useState([]);
    const params = useParams();

    useEffect(()=>{
        fetchPickupData();
    },[]);

    const fetchPickupData = () =>{
        try {
            http.post('customer/get-booked-appointment', { id: params.id }).then((res)=>{
                if ( res.data.status==='success' ) {
                    setAppointment(res.data.data.appointment);
                    setGoogleEventedit(res.data.data.google_eventedit);
                } else {
                    alert.info(res.data.message);
                }

            });
        }  catch (error) {
            alert.error(error);
        }        
    }

    function renderElement(){
        if(appointment){
            return <div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">{ appointment.date }</h5>
                                <p className="card-text">{ appointment.timeslot }-{ appointment.end_time }</p>

                                <a href={ googleEventedit } target="_blank">Add To Google</a>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }else{
            return <p>Loading.....</p>
        }

    }

    return(
        <div>
            <h1 className='mb-4 mt-4'>Appointment</h1>
            { renderElement() }
        </div>
    )
}