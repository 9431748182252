import { useEffect, useState } from 'react';
import AuthUser from './AuthUser';
import { useDocumentTitle } from '../utils/setDocumentTitle';
import { useAlert } from 'react-alert';
import { OrderStatus, TrackingLink} from '../utils/Helpers';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import moment from 'moment';

export default function PickupHistory() {
    useDocumentTitle('Pickups History');
    const alert = useAlert();
    const {http} = AuthUser();
    const [list, setList] = useState();

    useEffect(()=>{
        fetchPickupData();
    },[]);

    const fetchPickupData = () =>{
        try {
            http.post('customer/schedule-pickup-list').then((res)=>{
                if ( res.data.status==='success' ) {
                    setList(res.data.data.list);
                } else {
                    alert.info(res.data.message);
                }

            });
        }  catch (error) {
            alert.error(error);
        }        
    }

    function renderElement(){
        if(list){
            return <div>
                <div className='row'>
                    <div className='col-md-12 table-responsive'>
                        <table className='table table-hover table-dark mytbl'>
                            <thead>
                            <tr className='hide-mobile'>
                                <th>Order No</th>
                                <th>Tracking</th>
                                <th>Shipping</th>
                                <th>Pickup</th>
                                <th>Package Dispatch Location</th>
                                <th>Order Status</th>
                                <th>Date of Transaction</th>
                            </tr>
                            </thead>
                            <tbody>
                            {list.length>0 ? list.map((row, index) => {
                                return <tr className="table-secondary" key={index}>
                                    <td data-label="Order No">{row.id}</td>
                                    <td data-label="Tracking">{ parse(TrackingLink(row)) }</td>
                                    <td data-label="Shipping">{row.shipping_carrier}</td>
                                    <td data-label="Pickup">
                                        <span className="d-block">{row.pickup_address}</span>
                                        <span className="d-block">{row.pickup_state}</span>
                                        <span className="d-block">{row.pickup_county}</span>
                                        <span className="d-block">{row.pickup_date} at {row.pickup_time}</span>
                                    </td>
                                    <td data-label="Package Dispatch Location"> {row.drop_location}</td>
                                    <td data-label="Status" align='center'>
                                    {OrderStatus(row.order?.order_status)} <br />
                                    {
                                        row.order?.order_status==0 ? <Link to={'/schedule-pickup-payment/'+row.order?.id} className='btn btn-sm btn-primary'>Make Payment</Link> : null
                                    }
                                    </td>
                                    <td data-label="Transaction Date">{row.transaction_date}</td>
                                </tr>;
                            }) : <tr className="table-secondary"><td colSpan="10" className='text-center'>No data found!</td></tr>}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        }else{
            return <p>Loading.....</p>
        }

    }

    return(
        <div>
            <h1 className='mb-4 mt-4'>Pickup History</h1>
            { renderElement() }
        </div>
    )
}