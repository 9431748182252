import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import FormInput from '../utils/FormInput';
import AuthUser from './AuthUser';
import { useDocumentTitle } from '../utils/setDocumentTitle';

export default function ChangePassword() {
    useDocumentTitle('Change Password');
    const {http, user, updateUser} = AuthUser();
    const [userdetail,setUserdetail] = useState(user);

    const alert = useAlert();
    const [submitProcess, setSubmitProcess] = useState(false);

    const [values, setValues] = useState({
        current_password: '',
        password: '',
        password_confirmation: '',
    });

    const inputCurrentPass = {
        id: 1,
        name: "current_password",
        type: "password",
        placeholder: "Enter Current Password",
        errorMessage:
          "Password should be 8-20 characters and include at least 1 letter, 1 number and 1 special character!",
        label: "Current Password",
        pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
        required: true,
    };
    const inputs = [
    {
      id: 2,
      name: "password",
      type: "password",
      placeholder: "Enter New Password",
      errorMessage:
        "Password should be 8-20 characters and include at least 1 letter, 1 number and 1 special character!",
      label: "New Password",
      pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
      required: true,
    },
    {
      id: 3,
      name: "password_confirmation",
      type: "password",
      placeholder: "Confirm Password",
      errorMessage: "Passwords doesn't match!",
      label: "Confirm NewPassword",
      pattern: values.password,
      required: true,
    }
  ];

    useEffect(()=>{
        // fetchUserDetail();
    },[]);

    // const fetchUserDetail = () =>{
    //     http.post('customer/info').then((res)=>{
    //         setUserdetail(res.data.data.user);
    //     });
    // }

    const updateProfile = (e) => {
        e.preventDefault();
        try {
        setSubmitProcess(true);
        http.post('customer/reset-password', values).then((res)=>{
            if ( res.data.status==='success' ) {
                updateUser(res.data.data.user);
                alert.success(res.data.message);
            } else {
                alert.info(res.data.message);
            }
            setSubmitProcess(false);
            // navigate('/login')
        })
        } catch (error) {
        alert.error(error);
        }
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

    function renderElement(){
        if(userdetail){
            return <div>
                <div className='row'>
                    <div className='col-md-3'>
                        
                    </div>
                    <div className='col-md-6'>
                        <div className='card'>
                            <div className='card-body '>
                                <form onSubmit={updateProfile}>
                                    {
                                        user.has_pass!==false ? 
                                        <FormInput
                                            key={inputCurrentPass.id}
                                            {...inputCurrentPass}
                                            value={values[inputCurrentPass.name]}
                                            onChange={onChange}
                                        /> : null
                                    }
                                    
                                    {inputs.map((input) => (
                                    <FormInput
                                        key={input.id}
                                        {...input}
                                        value={values[input.name]}
                                        onChange={onChange}
                                    />
                                    ))}
                                    
                        
                                    <div className="text-center form-group mt-3">
                                    {
                                        submitProcess 
                                        ? 
                                        <button type="button" className="btn btn-primary mt-4">Please wait...</button> 
                                        : 
                                        <button type="submit" className="btn btn-primary mt-4">Update Password</button>
                                    }                      
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }else{
            return <p>Loading.....</p>
        }

    }

    return(
        <div>
            <h1 className='mb-4 mt-4'>Change Password</h1>
            { renderElement() }
        </div>
    )
}