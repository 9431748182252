
export default function LeftServices() {

    return(
        <div className="service-container">
            <h1>SHIKAPPS is business service application by SHIKA365 LLC </h1>
            <h1>Use SHIKAPPS for your</h1>
            <ul>
                <li>Prepaid Package pickups and drop offs</li>
                <li>Document Notary Appointments</li>
                <li>Fingerprints/Background check services</li>
            </ul>  
        </div>
    )
}