import { useState } from "react"
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";
import AuthUser from './AuthUser';
import { useDocumentTitle } from "../utils/setDocumentTitle";
import FormInput from "../utils/FormInput";
import LeftServices from "./common/LeftServices";
import RightServices from "./common/RightServices";
import Footer from "../navbar/footer";


export default function ForgotPassword() {
    useDocumentTitle('Forgot Password');
    const {http,user, setToken} = AuthUser();
    const [values,setValues] = useState({
        email: '',
        password: '',
        password_confirmation: '',
        otp: ''
    });
    const [otpSent,setOtpSent] = useState(false);
    const alert = useAlert();
    const [submitProcess, setSubmitProcess] = useState(false);

    const inputs = [
    {
      id: 1,
      name: "otp",
      type: "text",
      placeholder: "Enter OTP",
      errorMessage: "Otp is required!",
      label: "OTP",
      required: true,
    },
    {
      id: 2,
      name: "password",
      type: "password",
      placeholder: "Enter New Password",
      errorMessage:
        "Password should be 8-20 characters and include at least 1 letter, 1 number and 1 special character!",
      label: "Password",
      pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
      required: true,
    },
    {
      id: 3,
      name: "password_confirmation",
      type: "password",
      placeholder: "Confirm Password",
      errorMessage: "Passwords doesn't match!",
      label: "Confirm Password",
      pattern: values.password,
      required: true,
    }
  ];
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

    const submitForm = (e) =>{
        e.preventDefault();
        // api call
        setSubmitProcess(true);
        http.post('forgot-password-send-otp',{email:values.email}).then((res)=>{
            if ( res.data.status==='success' ) {
                alert.success(res.data.message);
                setOtpSent(true);
            } else {
                alert.show(res.data.message);
            }
            setSubmitProcess(false);
        })
    }
    
    const setPasswordWithOtp = (e) =>{
        e.preventDefault();
        // api call
        setSubmitProcess(true);
        http.post('set-forgot-password', values).then((res)=>{
            if ( res.data.status==='success' ) {
                setToken(res.data.data.user, res.data.data.token);
                alert.success(res.data.message);
            } else {
                alert.show(res.data.message);
            }
            setSubmitProcess(false);
        })
    }

    return(
        <div className="row justify-content-center0 pt-5 login-form-container">
            <div className="col-sm-12 col-md-6 login-form-left">

            <div className="left-toppart">
            <LeftServices />  
            <RightServices />  
            </div>
            <div className="left-bottompart">      
            <hr />
            <Footer />
            </div>
            </div>
            <div className="col-sm-12 col-md-6 login-form-body">
                <div className="card p-4">
                    <img className="login-logo" src="/logo.png" title="Shika365 Scheduler"></img>
                    <h1 className="text-center mb-3">Forgot Password?</h1>
                    { otpSent===false ? (
                        <form onSubmit={submitForm} autoComplete="off">
                        <div className="form-group">
                            <label>Email address:</label>
                            <input type="email" name="email" className="form-control" placeholder="Enter email"
                                onChange={onChange} required/>
                        </div>
                        <div className="form-group mt-3">
                            <Link to="/login">Back to Login</Link>
                        </div>
                        <div className="text-center form-group mt-3">
                            {
                                submitProcess 
                                ? 
                                <button type="button" className="btn btn-primary mt-4">Please wait...</button> 
                                : 
                                <button type="submit" className="btn btn-primary mt-4">Send Password Reset Code</button>
                            }
                        
                        </div>
                        </form>
                    ) : (
                        <form onSubmit={setPasswordWithOtp} autoComplete="off">
                        {inputs.map((input) => (
                        <FormInput
                            key={input.id}
                            {...input}
                            value={values[input.name]}
                            onChange={onChange}
                        />
                        ))}
                        <div className="text-center form-group mt-3">
                            {
                                submitProcess 
                                ? 
                                <button type="button" className="btn btn-primary mt-4">Please wait...</button> 
                                : 
                                <button type="submit" className="btn btn-primary mt-4">Set New Password</button>
                            }
                        
                        </div>
                        </form>
                    )}
                    
                    <div className="form-group mt-3 text-center">
                        Do not have account? <Link to="/register">Register</Link>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}