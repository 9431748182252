import { useEffect, useState } from 'react';
import AuthUser from './AuthUser';
import { useDocumentTitle } from '../utils/setDocumentTitle';
import { useAlert } from 'react-alert';
import {Link} from "react-router-dom";
import { PaymentMethod, PaymentStatus, ServiceType } from '../utils/Helpers';

export default function PaymentHistory() {
    useDocumentTitle('Payment Transactions');
    const alert = useAlert();
    const {http} = AuthUser();
    const [list, setList] = useState();

    useEffect(()=>{
        fetchInitialData();
    },[]);

    const fetchInitialData = () =>{
        try {
            http.get('customer/get-payment-transactions').then((res)=>{
                if ( res.data.status==='success' ) {
                    setList(res.data.data.list);
                } else {
                    alert.info(res.data.message);
                }

            });
        }  catch (error) {
            alert.error(error);
        }        
    }

    function renderElement(){
        if(list){
            return <div>
                <div className='row'>
                    <div className='col-md-12 table-responsive'>
                        <table className='table table-hover table-dark mytbl'>
                            <thead>
                            <tr>
                                <th>Order ID</th>
                                <th>Service Type</th>
                                <th>Transaction ID</th>
                                <th>Payment Method</th>
                                <th>Payment Status</th>
                                <th>Amount</th>
                                <th>Date of Transaction</th>
                            </tr>
                            </thead>
                            <tbody>
                            {list.length>0 ? list.map((row, index) => {
                                return <tr className="table-secondary" key={index}>
                                    <td data-label="Order ID">{row.id}</td>
                                    <td data-label="Service Type">{ServiceType(row)}</td>
                                    <td data-label="Transaction ID">{row.payment_information??'N/A'}</td>
                                    <td data-label="Payment Method">{PaymentMethod(row.payment_method)}</td>
                                    <td data-label="Payment Status">{PaymentStatus(row.paid)}
                                    {
                                        row.paid==0 ? <Link className='btn btn-primary btn-sm' to={'/schedule-pickup-payment/'+row.id}>Click to Pay</Link> : null
                                    }
                                    </td>
                                    <td data-label="Amount">${row.total}</td>
                                    <td data-label="Transaction Date">{row.transaction_date}</td>
                                </tr>;
                            })  : <tr className="table-secondary"><td colSpan="10" className='text-center'>No data found!</td></tr>}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        }else{
            return <p>Loading.....</p>
        }

    }

    return(
        <div>
            <h1 className='mb-4 mt-4'>Payment Transaction</h1>
            { renderElement() }
        </div>
    )
}