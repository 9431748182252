import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthUser from "./AuthUser";

export default function Home() {
    const {token, user} = AuthUser();
    const navigate = useNavigate();

    useEffect(()=>{

        if(token != undefined){
            navigate('/dashboard');
        } else {
            navigate('/login');
        }
    },[]);

    return(
        <div>Home page</div>
    )
}