import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import AuthUser from "./AuthUser";
import {useAlert} from "react-alert";
import { useDocumentTitle } from "../utils/setDocumentTitle";
import { Loader } from "../utils/Helpers";
import { useEffect } from "react";
const PaypalCheckoutButton = (props) => {
    useDocumentTitle('Checkout Payment');
    const {price, id} = props;
    const {http} = AuthUser();
    const alert = useAlert();
    const navigate = useNavigate();
    const initialOptions = {
        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
        currency: "USD",
        "disable-funding": ["credit"] // hide pay later btn
    };
    //const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [waitingMsg, setWaitingMsg] = useState('Please wait while the payment is being processed.');

    useEffect( ()=> {
        
    }, []);

    return (
        <div>
            {
                loading===true ?  Loader(waitingMsg) : null
            }
            <PayPalScriptProvider options={initialOptions}>
            <PayPalButtons
                createOrder={(data, actions) => {
                    return actions.order.create({
                        purchase_units: [
                            {
                                amount: {
                                    value: price,
                                },
                            },
                        ],
                    });
                }}
                onApprove={(data, actions) => {
                    setLoading(true);
                    return actions.order.capture().then((details) => {
                        //const name = details.payer.name.given_name;
                        http.post('customer/paypal-success',{
                            id: id,
                            details: details
                        }).then((res)=>{
                            if ( res.data.status==='success' ) {
                                setWaitingMsg(res.data.message);
                                // alert.success();
                                setTimeout ( ()=> {
                                    navigate(res.data.data.redirect);
                                }, 1500);                                
                            } else {
                                alert.info(res.data.message);
                                setLoading(false);
                            }                            
                            
                        });

                    });
                }}
                onError={(err) => {
                    //setError(err);
                    alert.error("PayPal Checkout onError: " + err);
                }}
                onCancel={() => {
                    // alert.info("Cancelled Payment");
                    // Display cancel message, modal or redirect user to cancel page or back to cart
                }}
            />
            </PayPalScriptProvider>
        </div>
    );
}
export default PaypalCheckoutButton