import moment from "moment";

export function OrderStatus(status) { // pickup order
    if ( status==0 ) {
        return <span className='btn btn-sm btn-warning'>Pending</span>;
    }
    else if ( status==1 ) {
        return <span className='btn btn-sm btn-secondary'>Received</span>;
    }
    else if ( status==2 ) {
        return <span className='btn btn-sm btn-info'>Assigned to Driver for Pickup</span>;
    }
    else if ( status==3 ) {
        return <span className='btn btn-sm btn-success'>Shipment delivered to Final Destination</span>;
    }
    else if ( status==4 ) {
        return <span className='btn btn-sm btn-danger'>Cancelled</span>;
    }
    else {
        return status;
    }
}

export function NotaryStatus(status) {
    if ( status==0 ) {
        return <span className='btn btn-sm btn-warning'>Pending</span>;
    }
    else if ( status==1 ) {
        return <span className='btn btn-sm btn-secondary'>Received</span>;
    }
    else if ( status==2 ) {
        return <span className='btn btn-sm btn-info'>Assigned to Certified Notary</span>;
    }
    else if ( status==3 ) {
        return <span className='btn btn-sm btn-success'>Notary Appointment Completed</span>;
    }
    else if ( status==4 ) {
        return <span className='btn btn-sm btn-danger'>Cancelled</span>;
    }
    else {
        return status;
    }
}

export function FingerprintStatus(status) {
    if ( status==0 ) {
        return <span className='btn btn-sm btn-warning'>Pending</span>;
    }
    else if ( status==1 ) {
        return <span className='btn btn-sm btn-secondary'>Received</span>;
    }
    else if ( status==2 ) {
        return <span className='btn btn-sm btn-info'>Assigned to a Fingerprint Agent</span>;
    }
    else if ( status==3 ) {
        return <span className='btn btn-sm btn-success'>Fingerprint Appointment Completed</span>;
    }
    else if ( status==4 ) {
        return <span className='btn btn-sm btn-danger'>Cancelled</span>;
    }
    else {
        return status;
    }
}

export function PaymentMethod(status) {
    if ( status==1 ) {
        return <span className="btn btn-sm btn-warning">Pay at Shop</span>
    } else {
        return <span className="btn btn-sm btn-success">PayPal</span>
    }
}

export function PaymentStatus(status) {
    if ( status==1 ) {
        return <span className="btn btn-sm btn-success">Paid</span>
    } else {
        return <span className="btn btn-sm btn-danger">Unpaid</span>
    }
}

export function TrackingLink(row) {

    const trackingLinkUrl = (trackingNumber, shippingCarrier) => {
        if ( shippingCarrier=='USPS' ) {
            return `<a href="https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=2&text28777=&tABt=true=&tLabels=${trackingNumber}" target="_blank">${trackingNumber}</a>`;
        }
        else if ( shippingCarrier=='UPS' ) {
            return `<a href="https://www.ups.com/track?loc=en_US&requester=ST&trackingNumber=${trackingNumber}" target="_blank">${trackingNumber}</a>`;
        }
        else if ( shippingCarrier=='FEDEX' ) {
            return `<a href="https://www.fedex.com/fedextrack/?trknbr=${trackingNumber}" target="_blank">${trackingNumber}</a>`;
        }
        else if ( shippingCarrier=='DHL' ) {
            return `<a href="https://www.dhl.com/us-en/home/tracking.html?tracking-id=${trackingNumber}" target="_blank">${trackingNumber}</a>`;
        }
        else if ( shippingCarrier=='AMAZON' ) {
            return `<a href="https://track.amazon.com/tracking/${trackingNumber}" target="_blank">${trackingNumber}</a>`;
        }
        else {
            return `<a href="https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=2&text28777=&tABt=true=&tLabels=${trackingNumber}" target="_blank">${trackingNumber}</a>`;
        }
    }

    let links = trackingLinkUrl(row.tracking_number, row.shipping_carrier);
    if ( row.addition_tracking_number!=null && row?.addition_tracking_number?.length>0 ) {
        for ( let i=0; i<row.addition_tracking_number.length; i++ ) {
            links = links+'<br />'+trackingLinkUrl(row.addition_tracking_number[i], row.shipping_carrier);
        }
    }

    return links;
    
}


export function ServiceType(row) {
    if ( row.order_schedule_type==1 ) {
        return <span>Package pickup</span>
    } else {
        if (row?.appoinment?.notary==1) {
            return <span>Notary Service</span>
        }            
        if (row?.appoinment?.fingure_print==1) {
            return <span>Finger Print Service</span>
        }
    }
}

export function ZoneWiseTime(time, timezone) {
    return moment.unix(time).tz(timezone).format("hh:mm a");
}

export function isWebview() {
    
    if (process.env.REACT_APP_WEBVIEW==1)
        return true; // for m.shikapps.com

    var standalone = window.navigator.standalone,
    userAgent = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test(userAgent),
    ios = /iphone|ipod|ipad/.test(userAgent);

    if (ios) {
        if (!standalone && safari) {
            // Safari
            return false;
        } else if (!standalone && !safari) {
            // iOS webview
            return true;
        };
    } else {
        if (userAgent.includes('wv')) {
            // Android webview
            return true;
        } else {
            // Chrome
            return false;
        }
    };
}

export function Loader(message) {
    return <div className="loadingbox">
        <h3>{message}</h3>
    </div>
}