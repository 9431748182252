import { Routes, Route, Link } from 'react-router-dom';
import ForgotPassword from '../components/ForgotPassword';
// import Home from '../components/home';
import Login from '../components/login';
import NotFound from '../components/NotFound';
import Register from '../components/register';
import ShikaDirectLogin from '../components/ShikaDirectLogin';
import Footer from './footer';
import PrivacyPolicy from '../components/PrivacyPolicy';
import Header from './header';

function Guest(props) {

    return (
        <div className='login-page'>      
        
            <div style={{width:'100%', display:'inline-block'}}>
                {props.showHeader && <Header />}
                <div className="container">
                    <Routes>
                        <Route path="/" element={<Login />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/forgot-password" element={<ForgotPassword />} />
                        <Route path="/shika365/:authToken/:redirectPath" element={<ShikaDirectLogin />} />
                        <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                    
                </div> 
                {props.showHeader && <Footer />} 
            </div>         
            
        </div>
    );
}

export default Guest;
