import { useState } from "react";
import AuthUser from "../components/AuthUser";
import jwtDecode from "jwt-decode";
import { useAlert } from "react-alert";

const useFetch = (url) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const {http,setToken} = AuthUser();
  const alert = useAlert();

  const handleGoogle = async (response) => {
    let googleData = jwtDecode(response.credential);
    setLoading(true);
    http.post('google-login',googleData).then((res)=>{
        if ( res.data.status==='success' ) {
            setToken(res.data.data.user, res.data.data.token);
        } else {
            alert.show(res.data.message);
        }
        setLoading(false);
    })
  };
  return { loading, error, handleGoogle, setLoading };
};

export default useFetch;