import { useEffect, useState } from 'react';
import { useParams} from 'react-router-dom';
// import { useAlert } from 'react-alert';
import AuthUser from './AuthUser';
import PaypalCheckoutButton from "./PaypalCheckoutButton";
import { useDocumentTitle } from '../utils/setDocumentTitle';

export default function PaypalPayment() {
    useDocumentTitle('Payment');
    const {http} = AuthUser();
    const [data, setData] = useState();
    const params = useParams();

    const fetchUserDetail = () =>{
        http.post('customer/get-order', { id: params.id }).then((res)=>{
            setData( res.data.data);
        });
    }

    useEffect(()=>{
        fetchUserDetail();
    }, []);

    

    function renderElement(){
        if(data){
            return <div>
                <div className="row justify-content-center pt-5">
                    <div className="col-sm-10">
                        <div className="card p-4">
                            <table className="table table-bordered">
                                <tbody>
                                <tr>
                                    <td>Order ID</td>
                                    <td>{ data.order.id }</td>
                                </tr>

                                <tr>
                                    <td>Total Amount</td>
                                    <td><strong>${ data.order.total }</strong></td>
                                </tr>
                                </tbody>
                            </table>

                        </div>
                        <div className="card p-4">
                            <div className="paypal-button-container text-center">
                                <PaypalCheckoutButton price={data.order.total} id={data.order.id} />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        }else{
            return <p>Loading.....</p>
        }
    }
    return(
        <div>
            <h1 className='mb-4 mt-4'>Process of Payment</h1>
            { renderElement() }
        </div>
    )
}