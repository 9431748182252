import { Link } from "react-router-dom";
import { useDocumentTitle } from "../utils/setDocumentTitle";
import AuthUser from "./AuthUser";
import Header from "../navbar/header";

export default function NotFound() {
    useDocumentTitle('404 - Page not found');
    const { isAuth } = AuthUser();
    return(
        <>
        <div style={{minHeight:600}}>
            <h2 className="text-center mt-5">404</h2>
            <h4 className="text-center">Page you are looking for not found!</h4>
            <div className="text-center">
            { isAuth() ?
                <Link className="navbar-brand" to="/dashboard">Dashboard</Link>
                : <Link className="navbar-brand" to="/">Home</Link>
            }
            </div>
        </div>
        </>
    )
}